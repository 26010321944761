<template>
  <div class="restics">
    <panes>
      <template v-slot:left>
        <div class="restic-list">
          <h1>
            Restic backups
            <base-button
              class="right"
              type="THEMED"
              iconType="PLUS"
              title="Create new Restic backup"
              v-on:click="createOne"
            />
          </h1>
          <ResticTable v-on:rowClick="handleRowClick" :savedAt="savedAt" />
        </div>
      </template>
      <template v-slot:right>
        <div class="restic-view">
          <ResticCreateEdit
            v-if="['resticEdit'].indexOf($route.name) > -1 && typeof selected === 'string'"
            :restic-id="selected"
            v-on:saved="handleSaved"
          />
          <ResticCreateEdit
            v-else-if="create === true"
            v-on:cancel="create = false"
            :restic-id="-1"
            v-on:saved="handleSaved"
          />
          <ResticSnapshots
            v-else-if="['resticSnaps'].indexOf($route.name) > -1 && typeof selected === 'string'"
            :restic-id="selected"
          />
          <ResticRead
            v-else-if="selected !== null && typeof selected === 'string' && create === false"
            :restic-id="selected"
            v-on:deleted="handleDeleted"
          />
        </div>
      </template>
    </panes>
  </div>
</template>
<script>
import Panes from '../components/Panes/Panes.vue';
import ResticTable from '../components/Restic/Table.vue';
import ResticRead from '../components/Restic/Read.vue';
import ResticCreateEdit from '../components/Restic/CreateEdit.vue';
import ResticSnapshots from '../components/Restic/Snapshots.vue';
import BaseButton from '../components/BaseButton/BaseButton.vue';

export default {
  name: 'Alert',
  components: {
    ResticTable,
    ResticRead,
    ResticCreateEdit,
    ResticSnapshots,
    Panes,
    BaseButton,
  },
  data() {
    return {
      selected: null,
      create: false,
      snaps: false,
      savedAt: null,
    };
  },
  methods: {
    handleRowClick(id) {
      this.create = false;
      this.snaps = false;
      this.selected = id;
      if (typeof id === 'string') {
        this.$router.replace(`/restic/${id}`);
      }
    },
    handleSaved() {
      this.savedAt = Date.now();
      this.$root.$emit('reloadTable');
    },
    handleDeleted() {
      this.handleSaved();
      this.selected = null;
    },
    createOne() {
      this.create = true;
      this.$router.replace('/restic/create');
    },
  },
  mounted() {
    if (this.$route.meta.create) {
      this.create = true;
      this.selected = null;
    }
    if (typeof this.$route.params.restic !== 'undefined') {
      this.selected = this.$route.params.restic;
    }
  },
};
</script>

<style lang="scss" scoped>
.restics {
  height: 100%;
}

.restic-list {
  height: 100%;
  overflow-y: auto;
}

.restic-view {
  height: 100%;
  overflow-x: auto;
}
</style>

<template>
  <div class="restic-snapshots" v-if="ready">
    <h1 class="title">{{ restic.name }}</h1>
    <card title="Snapshots">
      <ol>
        <li v-for="snap in restic.snapshots" :key="snap.time" class="snap-item">
          <a @click="loadSnap(snap.short_id)" class="snapshot-item monospace"
            >{{ snap.short_id }} - {{ $date(snap.time).format('LLL') }} ({{
              $date(snap.time).fromNow()
            }})</a
          >
        </li>
      </ol>
    </card>
    <card v-if="snapshot" :title="'Snapshot ' + snapshot">
      <div class="snapshot-actions">
        <a class="snapshot-reindex" @click="recreateIndex(snapshot)">Re-index snapshot</a>
        <input
          type="text"
          v-model="searchTerm"
          class="input-text searchbox"
          placeholder="Search for files..."
          @keyup="handleInput()"
        />
      </div>
      <hr class="hr" />
      <div v-if="snapshotTree" class="file-browser">
        <Teatree :roots="snapshotTree">
          <template slot="node-toggle" slot-scope="{ node }">
            <NodeToggle :node="node" />
          </template>
          <template slot="node-name" slot-scope="{ node }">
            <NodeName
              :node="node"
              :handleNodeLeftClick="() => {}"
              :handleNodeRightClick="() => {}"
            />
            <span v-if="node.data !== undefined" class="leaf-size">
              <span
                v-if="node.data.size !== undefined"
                @click="downloadFile(node)"
                title="Download file"
                >({{ (node.data.size || 0) | bytestohuman }})
                <i class="uil uil-file-download" />
              </span>
              <span v-else-if="node.data.children">({{ node.data.children }})</span>
            </span>
          </template>
        </Teatree>
      </div>
      <div class="loading" v-else>
        <div class="loader">
          <img :src="loadingImage" />
        </div>
      </div>
    </card>
  </div>
  <div class="loading" v-else>
    <div class="loader">
      <img :src="loadingImage" />
    </div>
  </div>
</template>

<script>
import Utils from '@/utils';
import { Teatree, NodeName, NodeToggle } from 'vue-teatree';
import Card from '../Card/Card.vue';

export default {
  name: 'ResticSnapshots',
  props: ['resticId'],
  components: {
    Teatree,
    NodeName,
    NodeToggle,
    Card,
  },
  data() {
    return {
      restic: null,
      snapshots: [],
      snapshot: null,
      snapshotTree: null,
      ready: false,
      searchTerm: '',
      searchDelay: null,
    };
  },
  methods: {
    async getRestic(id) {
      const response = await Utils.fetch(`/api/v1/restics/${id}`, {}, this).then((res) =>
        res.json(),
      );
      if (response.success) {
        this.restic = response.restic;

        if (this.restic && this.restic.initialized) {
          const deviceResponse = await Utils.fetch(
            `/api/v1/devices/${this.restic.device._id}`,
            {},
            this,
          ).then((res) => res.json());
          if (deviceResponse.success) {
            this.restic.device = deviceResponse.device;
          }

          this.snapshots = [];

          if (typeof this.restic.snapshots !== 'undefined') {
            this.snapshots = this.restic.snapshots.sort(
              (b, a) => new Date(a.time).getTime() - new Date(b.time).getTime(),
            );
          }
        }
      }
    },
    async recreateIndex(snapId) {
      await Utils.fetch(
        `/api/v1/restics/${this.resticId}/reindex/${snapId}`,
        {},
        this,
      ).then((res) => res.json());
    },
    resolvePaths(node, prefix) {
      let nodePath = '';
      if (node.name !== '/') {
        nodePath = `${prefix}/${node.name}`;
      } else {
        nodePath = prefix;
      }
      for (let i = 0; i < node.children.length; i += 1) {
        this.resolvePaths(node.children[i], nodePath);
      }
    },
    async getSnapshotFiles(snapId) {
      const snapshotTree = await Utils.fetch(
        `/api/v1/restics/${this.restic._id}/files/${snapId}`,
        {},
        this,
      ).then((res) => res.json());
      return snapshotTree;
    },
    async loadSnap(snapId) {
      this.snapshot = snapId;
      this.snapshotTree = null;

      this.snapshotTree = [await this.getSnapshotFiles(snapId)];
    },
    /**
     * Main function that handles the searching in the file tree. If the search
     * term is given it will look for matching nodes, else it will make sure
     * that all nodes are visible.
     */
    rootTreeMatchesSearchTerm(searchTerm, nodes) {
      if (nodes.length > 0) {
        if (searchTerm !== '') {
          for (let i = 0; i < nodes.length; i += 1) {
            this.treeMatchSearchTerm(searchTerm, nodes[i]);
          }
        } else {
          for (let i = 0; i < nodes.length; i += 1) {
            this.treeSetVisible(nodes[i]);
          }
        }
      }
    },
    /**
     * Recursive function that makes sure that the current node is visible as
     * well as all its children (and their children etc.).
     */
    treeSetVisible(node) {
      // eslint-disable-next-line no-param-reassign
      node.show = true;
      if (node.data) {
        // eslint-disable-next-line no-param-reassign
        node.data.children = node.children.length;
      }
      if (node.children.length > 0) {
        for (let i = 0; i < node.children.length; i += 1) {
          this.treeSetVisible(node.children[i]);
        }
      }
    },
    /**
     * Recursive function that decides if the current node should be visible.
     * Reasons to be visible are that one of the childs (or one of THEIR childs
     *  etc.) matches, or when the name of the current node matches.
     */
    treeMatchSearchTerm(searchTerm, node) {
      if (node.name.toLowerCase().indexOf(searchTerm) !== -1) {
        this.treeSetVisible(node);
        return true;
      }

      let nodeMatches = false;
      if (node.children.length > 0) {
        for (let i = 0; i < node.children.length; i += 1) {
          if (this.treeMatchSearchTerm(searchTerm, node.children[i])) {
            nodeMatches = true;
          }
        }
      }

      if (node.data) {
        // eslint-disable-next-line no-param-reassign
        node.data.children = node.children.filter((c) => c.show).length;
      }

      // eslint-disable-next-line no-param-reassign
      node.show = nodeMatches;
      return nodeMatches;
    },
    handleInput() {
      clearTimeout(this.searchDelay);
      this.searchDelay = setTimeout(this.handleSearch, 500);
    },
    handleSearch() {
      if (this.searchTerm !== '' && this.snapshotTree.length > 0) {
        this.rootTreeMatchesSearchTerm(this.searchTerm.toLowerCase(), this.snapshotTree);
      } else {
        this.rootTreeMatchesSearchTerm('', this.snapshotTree);
      }
    },
    async downloadFile(node) {
      this.$noty.info(`Starting download of ${node.path}...`, { timeout: 1000 });

      const payload = {
        file: node.path,
      };

      const res = await fetch(
        `/api/v1/restics/${this.restic._id}/files/${this.snapshot}/download`,
        {
          headers: {
            'X-Access-Token': localStorage.getItem('jwt'),
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify(payload),
        },
      );

      if (res.status !== 200) {
        res.json().then((obj) => {
          this.$noty.warning(obj.message);
        });
        return;
      }

      console.log('Downloading...');
      const blob = await res.blob();
      console.log('Done!');

      // Download the file for the user
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = node.name;
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
  },
  mounted() {
    this.getRestic(this.resticId).then(() => {
      this.ready = true;
    });
  },
};
</script>

<style>
.snap-item {
  margin: 4px;
}

.snapshot-item {
  padding: 4px;
  margin: 4px;
}

.snapshot-item:hover {
  background: var(--table-row-hover-color);
}

.snapshot-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.teatree {
  cursor: pointer;
  height: 100%;
  overflow: hidden;
}

.teatree-node {
  padding-right: 0.25rem;
}

.teatree-node-item {
  display: flex;
  align-items: center;
  height: 1.5rem;
  background: transparent;

  /* hack to make hover the full width of parent */
  padding-left: 100%;
  margin-left: -100%;
  padding-right: 100%;
  margin-right: -100%;
}

.teatree-node-item:hover {
  background-color: var(--file-browser-hover);
}

.teatree-node-item-selected {
  background-color: var(--file-browser-hover);
}

.teatree-node-item-name-padded-leaf,
.teatree-node-item-name-padded {
  padding-left: 0.75rem;
}

.teatree-node-item-icon {
  display: flex;
  align-items: center;
  margin-left: 0;
  color: var(--file-browser-font-color);
  width: 14px;
}

.teatree-node-item-name {
  display: inline-block;
  font-size: 13px;
  color: #a0aec0;
  margin-left: 0.5rem;
  user-select: none;

  /* truncate */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.leaf-size {
  margin-left: 10px;
  color: var(--file-browser-font-color);
}

.file-browser {
  background: var(--file-browser-background);
  border: 1px solid rgba(0, 0, 0, 0.4);
  padding: 10px;
}

.snapshot-title {
  padding-bottom: 14px;
  padding-top: 6px;
}

.snapshot-reindex {
  display: block;
  text-decoration: underline;
  color: nav(--link-color);
  font-size: 10px;
}
</style>

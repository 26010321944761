<template>
  <card title="Paths information" v-if="paths">
    <b>Includes</b>
    <ul>
      <li v-for="path in paths.includes" :key="path">{{ path }}</li>
    </ul>
    <b>Excludes</b>
    <ul>
      <li v-for="path in paths.excludes" :key="path">{{ path }}</li>
    </ul>
    <b>Checks</b>
    <ul>
      <li v-for="check in paths.checks" :key="check.path">
        {{ check.path }}<br />
        <u>Must exist</u>
        <span v-if="check.rules">
          and
          <span v-for="(rule, index) in check.rules" :key="rule">
            <span v-for="(value, name) in rule" :key="name">
              <u>{{ name }} = {{ duration(value * 1000) }}</u>
              <span v-if="index < check.rules.length - 1"> and </span>
            </span>
          </span>
        </span>
      </li>
    </ul>
  </card>
</template>

<script>
import Utils from '@/utils';
import Card from '../Card/Card.vue';

export default {
  name: 'ResticPaths',
  props: ['paths'],
  components: { Card },
  data() {
    return {
      ready: false,
    };
  },
  computed: {},
  methods: {
    duration(ms) {
      return Utils.duration(ms);
    },
  },
  mounted() {
    this.ready = true;
  },
};
</script>

<style scoped></style>

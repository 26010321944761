<template>
  <card title="Checks information" v-if="checks">
    <b>{{ checks.name }}</b>
    <ul v-if="checks && checks.paths && checks.paths.length">
      <li v-for="check in checks.paths" :key="check.path">
        {{ check.path }}<br />
        <u>Must exist</u>
        <span v-if="check.rules">
          and
          <span v-for="(rule, index) in check.rules" :key="index">
            <span v-for="(value, name) in rule" :key="name">
              <u>{{ name }} = {{ duration(value * 1000) }}</u>
              <span v-if="index < check.rules.length - 1"> and </span>
            </span>
          </span>
        </span>
      </li>
    </ul>
    <ul v-else>
      No checks defined
    </ul>
  </card>
</template>

<script>
import Utils from '@/utils';
import Card from '../Card/Card.vue';

export default {
  name: 'ResticChecks',
  props: ['checks'],
  components: { Card },
  data() {
    return {
      ready: false,
    };
  },
  computed: {},
  methods: {
    duration(ms) {
      return Utils.duration(ms);
    },
  },
  mounted() {
    this.ready = true;
  },
};
</script>

<style scoped></style>

<template>
  <card title="Server" v-if="server">
    <Property title="Server">
      {{ server.name }}
    </Property>
    <Property title="Server Token">
      <span v-if="server.expire">
        Expires in {{ $date(server.expire).fromNow(true) }} ({{ $date(server.expire) }})
      </span>
      <span v-else>No token stored</span>
    </Property>
    <span v-if="renew">
      <base-button
        :isSmall="true"
        iconType="SYNC"
        type="THEMED"
        v-on:click="renew"
        title="Fetch new token"
      />
    </span>
  </card>
</template>

<script>
import Property from '@/components/Util/Property.vue';
import Card from '../Card/Card.vue';
import BaseButton from '../BaseButton/BaseButton.vue';

export default {
  name: 'ResticServer',
  props: ['server', 'renew'],
  components: {
    Card,
    Property,
    BaseButton,
  },
  data() {
    return {
      ready: false,
    };
  },
  computed: {},
  methods: {},
  mounted() {
    this.ready = true;
  },
};
</script>

<template>
  <div>
    <table-component
      v-on:rowClick="handleRowClick"
      v-if="restics"
      columnName="columns-Restic"
      :enable-column-filter="true"
      :columns="tableColumns"
      :table-data="restics"
      :rowsClickable="true"
    />
  </div>
</template>

<script>
import Utils from '@/utils';
import TableComponent from '../Table/TableComponent.vue';

export default {
  components: { TableComponent },
  name: 'ResticTable',
  props: ['savedAt'],
  data() {
    return {
      ajaxCompleted: false,
      restics: [],
      tableColumns: [
        {
          title: 'Avail',
          key: 'available',
        },
        {
          title: 'Name',
          key: 'name',
        },
        {
          title: 'Size',
          key: 'totalSize',
        },
        {
          title: 'Snaps',
          key: 'snapshotsLength',
        },
        {
          title: 'Init',
          key: 'isInitialized',
        },
        {
          title: 'Sched',
          key: 'isAvailable',
        },
        {
          title: 'Last run',
          key: 'lastRun',
        },
        {
          title: 'Duration',
          key: 'lastDuration',
        },
      ],
    };
  },
  watch: {
    savedAt() {
      this.getrestics();
    },
  },
  methods: {
    async getrestics() {
      const response = await Utils.fetch('/api/v1/restics', {}, this).then((res) => res.json());
      if (response.success) {
        this.ajaxCompleted = true;
        this.restics = response.restics;
        this.mapResticsToTableData();
      }
    },
    handleRowClick(id) {
      this.$emit('rowClick', id);
    },
    duration(ms) {
      return Utils.duration(ms);
    },
    mapResticsToTableData() {
      this.restics.forEach((restic, index) => {
        this.restics[index] = {
          ...restic,
          totalSize: restic.stats?.total_size && this.$bytestohuman(restic.stats.total_size),
          snapshotsLength: restic.snapshots.length,
          isScheduled: restic.schedule && restic.schedule.enabled,
          isInitialized: !!restic.isInitialized,
          id: restic._id,
        };
      });
    },
  },
  mounted() {
    this.getrestics();
    this.$root.$on('reloadTable', () => {
      this.getrestics();
    });
  },
};
</script>

<style lang="scss" scoped>
.restic-table {
  overflow-x: auto;
  padding-bottom: 30px;
}

.restic-table table {
  width: 100%;
  border-collapse: collapse;

  .loader {
    text-align: center;
    padding: 20px 0;

    img {
      height: 30px;
    }
  }

  tr {
    height: 30px;
  }

  tbody tr {
    cursor: pointer;

    &.selected {
      background: var(--table-row-hover-color);
    }

    &:hover {
      background: var(--table-row-hover-color);
    }
  }

  th,
  td {
    padding: 0 10px;
  }

  thead tr th {
    text-align: left;
    background: var(--table-header-color);
  }

  th {
    font-weight: normal;
  }
}

.bullet {
  height: 10px;
  width: 10px;
  display: inline-block;
  border-radius: 100%;

  &.blue {
    background: rgb(88, 195, 220);
  }

  &.green {
    background: #4dbd74;
  }

  &.red {
    background: #f86c6b;
  }
}
</style>
